<template>
    <div class="modal-single-option-selector">
        <div class="head flex-row flex-between items-start">
            <div class="title m-b-16" :class="options.titleClass" v-html="$translate(title)" />
        </div>
        <div class="body" :class="{ 'm-t-0': options.hideHeader }">
            <ul class="options">
                <li
                    v-for="option of list"
                    :key="option.id"
                    class="option"
                    :class="{ selected: option.id === (selectedOption || {}).id }"
                    @click="onClickOption(option)"
                >
                    <i class="material-icons m-r-8">
                        radio_button_{{ option.id === (selectedOption || {}).id ? 'checked' : 'unchecked' }}
                    </i>
                    {{ option.name }}
                </li>
            </ul>
        </div>
        <div v-if="buttons" class="buttons">
            <div class="flex-row">
                <button @click="onClickButton(idx)" :key="idx" v-for="(btn, idx) in buttons" :class="btn.class">
                    {{ btn.label | translate }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalSingleOptionSelector',
    props: ['options'],
    data: () => ({
        selectedOption: null,
    }),
    computed: {
        title() {
            return (this.options || {}).title
        },
        list() {
            return (this.options || {}).list
        },
        buttons() {
            return (this.options || {}).buttons || []
        },
    },
    methods: {
        onClickOption(option) {
            if (!option) return
            if (option.id === (this.selectedOption || {}).id) {
                this.selectedOption = null
                return
            }

            this.selectedOption = option
        },
        onClickButton(idx) {
            if (!this.selectedOption && idx !== 0) {
                this.$toast.error('주선자를 선택해주세요')
                return
            }

            if (idx === 0) {
                this.$emit('close')
            } else if (idx === 1) {
                this.$emit('close', this.selectedOption)
            }
        },
    },
}
</script>
